import React from "react";
import { db } from "../utils/firebase";
import { collection, addDoc } from "firebase/firestore";

export default function Contact() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  function validate(name, email, message) {
    if (name === "" || email === "" || message === "") {
      return false;
    }
    return true;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    // validate the form values
    if (validate(name, email, message)) {
      try {
        const docRef = await addDoc(collection(db, "messages"), {
          name: name,
          email: email,
          message: message
        });
        console.log("Document written with ID: ", docRef.id);
        // reset the form after a successful submission
        document.getElementById('contactForm').reset();
      } catch (e) {
        alert("Error adding document: ", e);
      }
    } else {
      alert("One or more fields were invalid.");
    }
  }

  return (
    <section id="contact" className="relative">
      <div className="container px-5 py-10 mx-auto flex sm:flex-nowrap flex-wrap">
        <form
          name="contact"
          id="contactForm"
          onSubmit={handleSubmit}
          className="p-6 max-w-sm mx-auto flex flex-col items-center space-x-4">
          <h2 className="text-white sm:text-4xl text-3xl mb-1 font-medium title-font">
            Contact Me
          </h2>
          <p className="leading-relaxed mb-5 text-center">
            Looking to get in touch? Fill out the contact form with your inquiry.
          </p>
          <div className="relative mb-4">
            <label htmlFor="name" className="leading-7 text-sm text-gray-400">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              required
              class="peer w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={(e) => setName(e.target.value)}
            />
            <p class="invisible peer-invalid:visible text-red-500 text-xs italic">Please enter your full name.</p>
          </div>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-400">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="peer w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={(e) => setEmail(e.target.value)}
            />
            <p class="invisible peer-invalid:visible text-red-500 text-xs italic">Please enter a valid email address.</p>
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="message"
              className="leading-7 text-sm text-gray-400">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              className="peer w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 h-32 text-base outline-none text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              onChange={(e) => setMessage(e.target.value)}
            />
            <p class="invisible peer-invalid:visible text-red-500 text-xs italic">Please enter a message.</p>
          </div>
          <button
            type="submit"
            className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
            Submit
          </button>
        </form>
      </div>
    </section>
  );
}