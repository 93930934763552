export const projects = [
    {
      title: "React Reserve",
      subtitle: "MERN Stack",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
      image: "./project-1.gif",
      link: "https://reactbootcamp.com",
    },
    {
      title: "React Tracks",
      subtitle: "React and Python",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
      image: "./project-2.gif",
      link: "https://reedbarger.com",
    },
    {
      title: "DevChat",
      subtitle: "React and Firebase",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
      image: "./project-3.gif",
      link: "https://jsbootcamp.com",
    },
    {
      title: "Epic Todo App",
      subtitle: "React Hooks",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
      image: "./project-4.gif",
      link: "https://pythonbootcamp.com",
    },
  ];

  export const employers = [
    {
      "company": "SRI International Sarnoff",
      "title": "Software Developer",
      "link": "https://www.sri.com/",
      "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/SRI_International_logo.svg/330px-SRI_International_logo.svg.png",
      "description": `Developed core components for several projects including a force protection application suite 
      and a biometric identification system. Built a mobile application for the Android platform to remotely view and control
      multiple PTZ cameras around military installations while applying a proprietary blob detection algorithm in real-time.`
    },
    {
      "company": "Morgan Stanley",
      "title": "Software Developer",
      "link": "https://www.morganstanley.com",
      "image": "https://upload.wikimedia.org/wikipedia/commons/3/34/Morgan_Stanley_Logo_1.svg",
      "description": "Worked in the Merchant Banking and Real Estate Investing (MBREI) group, designing and implementing applications to support the firm's real estate investments."
    },
    {
      "company": "Duality Group",
      "title": "Director of Engineering",
      "link": "https://www.dualitygroup.com",
      "image": "https://s3.amazonaws.com/finn--production/uploads/public_41edfb43-cd0d-4b7f-ab0a-09d0edacd9ef_image.jpeg",
      "description": `Designed and implemented the automated trading platform in Python leveraging proprietary machine
      learning techniques. The core architecture was designed from the ground up utilizing microservices with RESTful
      APIs and deployed on AWS via ECS and Fargate.
      `
    },
    {
      "company": "Brex",
      "title": "Senior Software Engineer",
      "link": "https://www.brex.com",
      "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Brex_Inc._Corporate_Logo.png/330px-Brex_Inc._Corporate_Logo.png",
      "description": "Currently working here as a Senior Software Engineer on the Expense Management team."
    }
  ];

  export const skills = [
    "Python",
    "C#",
    "Java",
    "Kotlin",
    "AWS",
    "Distributed Systems",
    "SQL (MS-SQL, Postgres, AWS RDS)",
    "NoSQL (MongoDB)"
  ];

  export const testimonials = [
    {
      "quote": "Great Guy",
      "image": "./logo192.png",
      "name": "John Smith",
      "company": "Morgan Stanley"
    },
    {
      "quote": "Much Danger",
      "image": "./logo192.png",
      "name": "Kenny Loggins",
      "company": "Archer Inc"
    }
  ];