import './App.css';

import React from "react";
import About from "./components/About";
import Contact from "./components/Contact";
import Navbar from "./components/Navbar";
// import Projects from "./components/Projects";
import Skills from "./components/Skills";
// import Testimonials from "./components/Testimonials";
import Employers from './components/Employers';

function App() {
  return (
    <main className="text-gray-400 bg-gray-900 body-font">
        <Navbar />
        <About />
        <Employers />
        {/* <Projects /> */}
        <Skills />
        {/* <Testimonials /> */}
        <Contact />
    </main>
  );
}

export default App;
